<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ActividadesProyectoComponente />
    </Layout>
</template>
<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ActividadesProyectoComponente from "./actividades-proyecto-componente.vue";
export default {
    page: {
        title: "Proyectos",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        ActividadesProyectoComponente
    },
    data() {
        return {
            title: "Actividades Proyecto",
            items: [
                {
                    text: "Proyectos",
                    href: `/proyectos/proyectos`,
                },
                {
                    text: "Lugares Instalación",
                    href: `/proyectos/lugaresInstalacion/${this.$route.params.codigoProyecto}`,
                },
                {
                    text: "Actividades Proyecto",
                    active: true,
                },
            ],
        };
    },
};
</script>